import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import SettleTable from '../../components/SettleTable';

const File = () => (
    <>
        <Segment >
            <Header as='h3'>管理结算</Header>
            <SettleTable />
        </Segment>
    </>
);

export default File;
